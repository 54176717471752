* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --color-info-header: #BBD6B8;
  --color-info-body: #94AF9F;
  --color-card-dark: #618684;
  --color-card-darkest: #3d5352;
  --color-card-light: #94AF9F;
  --color-background: #23232dcb;
  --color-info-text: black;
  --color-main-section: #AEC2B6;
}

body {
  height: 100vh;
  background-color: var(--color-background);
  padding: 1em;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  background-color: var(--color-main-section);
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow-y: hidden;
}

.left {
  width: 288px;
  min-width: 288px;
  background-color: var(--color-info-body);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: 0.2s;
}

.right {
  width: 83%;
  height: 100%;
  margin: 2em;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
  padding-bottom: 3em;
}

.right::-webkit-scrollbar {
  display: none;
}

header {
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 2em;
  text-align: center;
  background-image: url("./images/bg1.jpg");
  background-color: var(--color-card-darkest);
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
  color: #fff;
  position: relative;
}

header > p {
  color: #ffc406;
}

header > h2 {
  color: white;
}

.technologies {
  flex-direction: column;
  margin-top: 1em;
}

h2 {
  color: var(--color-info-text);
}

.technologies,
.technologies-container {
  display: flex;
  gap: 1em;
  color: var(--color-info-text);
}

.technologies-container {
  flex-wrap: wrap;
  row-gap: 2em;
  justify-content: center;
}

.technologies > h2 {
  align-self: flex-start;
}

.technology {
  width: 60px;
  height: 60px;
  font-size: 0.8em;
  text-align: center;
  flex-basis: 9%;
  font-weight: bold;
}

.technology > img {
  width: 40px;
}

.works {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

.works > h2 {
  margin-bottom: 1em;
}

article {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
}

.light {
  background-color: var(--color-card-dark);
  color: #20202a;
  padding: 1em 2em;
}

.dark {
  background-color: var(--color-card-light);
  color: #20202a;
  padding: 1em 2em;
}

.light,
.dark {
  min-height: 300px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex-basis: 50%;
}

.text > h3 {
  color: var(--color-info-text);
}

.text > p {
  color: #4e4e5f;
}

a {
  text-decoration: none;
  font-size: 0.6em;
  text-align: center;
  color: #ffffff;
}

.work-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.work-buttons > a {
  font-size: 1em;
  color: #000000;
  background-color: #ffc406;
  width: 150px;
  padding: 0.5em 1em;
}

.work-buttons > p.inactive {
  font-size: 1em;
  color: #000000;
  background-color: gray;
  width: 150px;
  padding: 0.5em 1em;
}

.work-buttons > a:nth-child(2) {
  background-color: #6e6e88;
  color: #000000;
}

.text > a:hover {
  opacity: 0.9;
}

a:active {
  transform: scale(0.97);
}

.image {
  flex-basis: 50%;
}

.image > img {
  width: 100%;
}

.info-bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-header {
  background-color: var(--color-info-header);
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  justify-content: center;
}

.info-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #111827;
}

.info-avatar > img {
  width: 100%;
  border-radius: 50%;
}

.info-name {
  color: var(--color-info-text);
}

.info-title,
.info-email {
  color: var(--color-info-text);
  font-size: 0.8em;
}

.info-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;
  padding: 2em;
  height: 300px;
  background-color: var(--color-info-body);;
}

.body-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.2em;
  justify-content: space-between;
  padding-bottom: 1em;
  border-bottom: 1px solid #5e5e63;
  font-size: 0.8em;
  height: 200px;
}

.body-details h4 {
  display: inline;
  margin-right: auto;
  color: var(--color-info-text);
  font-weight: 400;
}

.body-details span {
  color: var(--color-info-text);;
  font-size: 1em;
  justify-self: flex-end;
}

.info-socials {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #23232d;
}

.info-socials > a {
  color: #ffc406;
  font-size: 1em;
}

.sidebar-toggle-btn,
.sidebar-toggle-btn2 {
  position: absolute;
  right: 5%;
  top: 2%;
  color: #ffc406;
  font-size: 1.5em;
  cursor: pointer;
  display: none;
}

.sidebar-toggle-btn2 {
  left: 3%;
  text-align: left;
}

footer {
  height: 30vh;
  background-color: #111827;
  padding: 1em 2em;
  color: #ffc406;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

footer h2 {
  color: #ffffff;
}

.footer-links {
  display: flex;
  justify-content: space-around;
}

.footer-links > div {
  text-align: center;
  cursor: pointer;
}

footer a {
  font-size: unset;
  color: unset;
}

footer i {
  font-size: 2em;
  margin-bottom: 0.2em;
}

@media (max-width: 920px) {
  .left {
    width: 0;
    min-width: 0;
    overflow: hidden;
  }

  .right {
    margin: auto;
    width: 95%;
  }

  .active {
    width: 100%;
    height: 95%;
    min-width: 288px;
    position: absolute;
    z-index: 1;
  }

  .technologies {
    justify-content: flex-start;
  }

  .App {
    padding: 2em 0;
  }

  header {
    height: 300px;
    gap: 2em;
    line-height: 2em;
  }

  .technology {
    flex-basis: 15%;
  }

  .sidebar-toggle-btn,
  .sidebar-toggle-btn2 {
    display: block;
  }

  .hide {
    display: none;
  }

  .body-details {
    font-size: 1em;
  }

  .info-title,
  .info-email {
    font-size: 1.2em;
  }
}

.text,
.image {
  position: relative;
  opacity: 0;
  transition: 0.4s all ease;
}

.text {
  transform: translateX(-100px);
}

.image {
  transform: translateY(100px);
}

.text.reveal,
.image.reveal {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 580px) {
  article {
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  }

  header {
    font-size: 0.9em;
    border-radius: 12px;
  }

  .technologies {
    margin-top: 3em;
    margin-bottom: 2em;
  }
  .technologies > h2 {
    width: 100%;
    margin-bottom: 1em;
  }

  .works {
    gap: 1em;
  }

  .text,
  .image {
    flex-basis: 100%;
  }

  .text {
    align-items: center;
    text-align: center;
  }

  footer {
    height: unset;
    padding-bottom: 2em;
    border-radius: 12px;
    align-items: center;
  }

  .footer-links {
    flex-wrap: wrap;
    gap: 2em;
  }
}

@media (max-width: 431px) {
  .work-buttons {
    justify-content: center;
  }
}

@media (max-width: 361px) {
  header {
    height: unset;
  }
}
